import Cookies from 'js-cookie'

const TokenKey = 'jakpreneur-dashboard-token'

export function getToken () {
  const token = Cookies.get(TokenKey)
  if (token) {
    return JSON.parse(token)
  } else {
    return null
  }
}

export function setToken (token, needStringify = false) {
  const stringified = needStringify ? JSON.stringify(token) : token
  return Cookies.set(TokenKey, stringified, { expires: 1 })
}

export function removeToken () {
  Cookies.remove(TokenKey)
}
