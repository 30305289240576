import axios from 'axios'
import store from '../store'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL, // api
  // baseURL: 'https://api-jakpreneur.olsera.co.id/',
  timeout: 120000 // request timeout
})

function generateErrorMessage (error) {
  let errorCode = 0
  let statusCode = 500
  let message = 'Connection timeout, please check your internet connection'
  let string = 'Connection timeout, please check your internet connection'

  if (error.response) {
    if (error.response.data !== 'application/json') {
      const errorError = error.response.data
      let errArr = errorError
      string = errArr

      // if (typeof errorError !== 'string') {
      //   string = ''
      //   errArr = Object.values(errorError)
      //   for (let index = 0; index < errArr.length; index++) {
      //     string += '- ' + errArr[index]
      //   }
      // }

      if (error.response.data) {
        statusCode = error.response.data.status_code
        message = error.response.data.message
        errorCode = error.response.data
      } else {
        string = message
      }
    }
  }

  return {
    ...error,
    statusCode,
    message,
    string,
    errorCode
  }
}

// request interceptor
service.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) {
      if (store.getters.token) {
        config.headers['Authorization'] = 'Bearer ' + token.access_token
      }
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 401) {
    setTimeout(() => {
      window.location.href = '/'
    }, 3000)
    const errorXHR = generateErrorMessage(error)
    return Promise.reject(errorXHR)
  } else {
    const errorXHR = generateErrorMessage(error)
    return Promise.reject(errorXHR)
  }
})

export default service
