const DEV_MODE = process.env.VUE_APP_STAGE_LEVEL !== 'prod'

const app = {
  state: {
    sidebarCollapse: false,
    snackbar: {
      show: false,
      message: '',
      type: ''
    },
    internetStatus: navigator.onLine,
    hostName: ''
  },

  mutations: {
    SET_SIDEBAR_COLLAPSE: (state, payload) => {
      state.sidebarCollapse = payload
    },
    SET_SNACKBAR: (state, data) => {
      const obj = {
        ...data,
        show: true
      }
      state.snackbar = obj
    },
    SET_INTERNET_STATUS: (state, payload) => {
      state.internetStatus = payload
    },
    SET_HOSTNAME: (state, payload) => {
      state.hostName = payload
    }
  },

  actions: {
    toggleSidebarCollapse ({ commit, state }) {
      commit('SET_SIDEBAR_COLLAPSE', !state.sidebarCollapse)
    },
    toggleSidebarCollapseMobile ({ commit }, payload) {
      commit('SET_SIDEBAR_COLLAPSE', payload)
    },
    showSnackbar ({ commit }, data) {
      commit('SET_SNACKBAR', data)
    },
    setInternetStatus({ commit }, payload) {
      commit('SET_INTERNET_STATUS', payload)
    },
    setHostName ({ commit }) {
      let host = ''
      const hostName = location.host
      const splitHostname = hostName.split(/[.\*+/:_]/)[0]
      if (DEV_MODE) {
        if (splitHostname === 'd21phl0dsql5vt') {
          host = 'monitoring'
        }
      } else {
        host = splitHostname
      }
      commit('SET_HOSTNAME', host)
    }
  }
}

export default app
