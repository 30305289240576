import { loginByEmail, logout } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { setItem, getItem } from '@/utils/localStorage'

const user = {
  state: {
    token: getToken() ? getToken() : null,
    user: getItem('user')
  },

  mutations: {
    SET_TOKEN (state, payload) {
      setToken(payload, true)
      state.token = payload
    },
    SET_USER: (state, user) => {
      state.user = user
      setItem('user', user)
    }
  },

  actions: {
    loginByEmail ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        loginByEmail(payload).then(response => {
          commit('SET_TOKEN', response.data.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    logOut ({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(response => {
          commit('SET_TOKEN', '')
          removeToken()
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    setUser ({ commit }, payload) {
      commit('SET_USER', payload)
    }
  }
}

export default user
