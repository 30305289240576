import request from '@/utils/request'
import Vuetify from '@/plugins/vuetify'
var langId = Vuetify.preset.lang.current

const endpoint = '/api/v1/'

export function loginByEmail (data) {
  return request({
    url: endpoint + langId + '/login',
    method: 'post',
    data
  })
}

export function logout () {
  return request({
    url: endpoint + langId + '/logout',
    method: 'post'
  })
}

export function forgotPassword (data) {
  return request({
    url: endpoint + langId + '/forgot-password',
    method: 'post',
    data
  })
}

export function resetPassword (data) {
  return request({
    url: endpoint + langId + '/reset-password',
    method: 'post',
    data
  })
}
